import React, { useState } from "react";
import { Button, Alert } from "reactstrap";
import Highlight from "../components/Highlight";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

export const Swagger = () => {
    return (
        <SwaggerUI url="/q/openapi.json" />
    );
};

export default withAuthenticationRequired(Swagger, {
    onRedirecting: () => <Loading />,
});
