import React, {Fragment, useState} from "react";

import Hero from "../components/Hero";
import Content from "../components/Content";
import AppraisalForm from "../components/AppraisalForm";

const Home = () => {
    return (
        <Fragment>
            <Hero />
            {/*<AppraisalForm/>*/}
            <Content />
        </Fragment>
    )
}

export default Home;
